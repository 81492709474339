import { v4 as uuid } from 'uuid';

export enum BlockType {
    UNKNOWN = -1,
    TITLE = 0,
    IMAGE = 1
}

export class BlockData {
    type: BlockType
    id: string
    data: any

    constructor(type:BlockType, data: any) {
        this.id = uuid();
        this.type = type;
        this.data = data;
    }
}