import { Box, Button, Modal, Stack } from "@mui/material";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// ReactQuill+React bug workaround
// https://github.com/zenoamaro/react-quill/issues/784
class CustomQuill extends ReactQuill {
    destroyEditor() {
        if (!this.editor) return;
        this.unhookEditor(this.editor);
    }

    instantiateEditor() {
        if (this.editor) {
            this.hookEditor(this.editor);
        }
        super.instantiateEditor();
    }
}

type EditableTextUpdateHandler = (text: string) => void;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const modules = {
    toolbar: [
        [{ font: [] }, { size: [] }],
        [],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [],
        ["clean"],
    ],
};

function EditableText(props: { text: string, isEditable: boolean, updateHandler: EditableTextUpdateHandler }) {
    const [value, setValue] = useState(props.text);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const applyText = () => {
        props.updateHandler(value);
        setOpen(false);
    }

    if (!props.isEditable) {
        return (
            <Box>
                <div className='ql-editor' dangerouslySetInnerHTML={{ __html: props.text }} />
            </Box>
        )
    }

    return (
        <Box>

            <div className='ql-editor' onClick={handleOpen} dangerouslySetInnerHTML={{ __html: props.text }} />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CustomQuill theme="snow" modules={modules} value={value} onChange={setValue} />
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        mt={2}
                    >
                        <Button variant="outlined" sx={{ width: 120 }} color='primary' onClick={handleClose} >
                            キャンセル
                        </Button>
                        <Button variant="contained" sx={{ width: 120 }} color='primary' onClick={applyText} >
                            変更
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    )
}

export default EditableText;