import useTitle from "../hooks/UseTitle"
import BlocksContainer from "../components/blocks/BlocksContainer"

import Box from '@mui/material/Box';

function TopPage() {
    useTitle("ONEページ")

    return (
        <Box
            height="95vh"
            width="100vh"
            display="flex"
            flexDirection="column"
            m="auto"
            overflow="auto"
            sx={{ minWidth: 1200 }}
        >
            <BlocksContainer />
        </Box>
    )
}

export default TopPage;