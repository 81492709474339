import { BlockData } from "../models/BlockData"

type DataManagerUpdateHandler = (data: Array<BlockData>) => void;

export default class DataManager {

    private timeline: Array<Array<BlockData>> = []
    private updateHandler: DataManagerUpdateHandler

    constructor(updateHandler: DataManagerUpdateHandler) {
        this.updateHandler = updateHandler;
    }

    addBlock(block: BlockData) {

        let theCopy = JSON.parse(JSON.stringify(block))

        if (this.timeline.length === 0) {
            this.timeline.push([theCopy]);
            this.updateHandler(this.getCurrent());
            return;
        }

        let current = this.getCurrent();
        current.push(theCopy);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    getCurrent(): Array<BlockData> {
        if (this.timeline.length === 0) {
            return [];
        }
        return JSON.parse(JSON.stringify(this.timeline[this.timeline.length - 1]));
    }

    undo() {
        if (this.timeline.length === 0) {
            return;
        }

        this.timeline.pop();
        this.updateHandler(this.getCurrent());
    }

    canUndo() {
        return (this.timeline.length > 0)
    }

    moveUp(id: string) {
        let current = this.getCurrent();
        if (current.length <= 1) { return; }

        let idx = current.findIndex(e => e.id === id);
        if (idx <= 0) { return; }

        let removed = current.splice(idx, 1);
        current.splice(idx - 1, 0, removed[0]);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    moveDown(id: string) {
        let current = this.getCurrent();
        if (current.length <= 1) { return; }
    
        let idx = current.findIndex(e => e.id === id);
        if (idx === -1 || idx === current.length - 1) { return; }

        let removed = current.splice(idx, 1);
        current.splice(idx + 1, 0, removed[0]);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    moveTop(id: string) {
        let current = this.getCurrent();
        if (current.length <= 1) { return; }

        let idx = current.findIndex(e => e.id === id);
        if (idx <= 0) { return; }

        let removed = current.splice(idx, 1);
        current.splice(0, 0, removed[0]);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    moveBottom(id: string) {
        let current = this.getCurrent();
        if (current.length <= 1) { return; }
    
        let idx = current.findIndex(e => e.id === id);
        if (idx === -1 || idx === current.length - 1) { return; }

        let removed = current.splice(idx, 1);
        current.splice(current.length, 0, removed[0]);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    duplicate(id: string) {
        let current = this.getCurrent();
        if (current.length === 0) { return; }
    
        let idx = current.findIndex(e => e.id === id);
        if (idx === -1) { return; }

        let target = current[idx];
        let theCopy = JSON.parse(JSON.stringify(new BlockData(target.type, target.data)))
        current.splice(idx + 1, 0, theCopy);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    delete(id: string) {
        let current = this.getCurrent();
        if (current.length === 0) { return; }
    
        let idx = current.findIndex(e => e.id === id);
        if (idx === -1) { return; }

        current.splice(idx, 1)

        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }

    updateData(id: string, data: any) {
        let current = this.getCurrent();
        if (current.length === 0) { return; }
    
        let idx = current.findIndex(e => e.id === id);
        if (idx === -1) { return; }

        let target = current[idx];
        target.data = JSON.parse(JSON.stringify(data));
        current.splice(idx, 1, target);
        this.timeline.push(current);
        this.updateHandler(this.getCurrent());
    }
}