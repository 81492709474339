import { Box, styled } from '@mui/material';
import { ChangeEventHandler } from 'react';
import useHover from '../../hooks/UseHover';
import { BlockData } from '../../models/BlockData';
import placeholder from '../../public/img_placeholder.jpg'
import { BlockEditHandler } from './BlocksContainer';

export class BlockImageData {
    url?: string

    constructor(url?: string) {
        this.url = url;
    }
}

const Input = styled('input')({
    display: 'none',
});

const Image = styled('img')({
    width: '100%',
    height: '100%',
    'object-fit': 'cover'
});

function BlockImage(props: { data: BlockData, isEditing: boolean, editHandler: BlockEditHandler }) {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
        const files = event.currentTarget.files;

        if (!files || files?.length === 0) return;
        const file = files[0];
        if (!file.type.includes('image/')) {
            event.currentTarget.value = '';
            return;
        }

        const reader = new FileReader();
        reader.onload = function () {
            props.editHandler(props.data.id, new BlockImageData(reader.result as string));
        }
        reader.readAsDataURL(file);
    }

    return (
        <Box
            sx={{
                p: '10px',
                height: 350,
                border: props.isEditing ? '1px dashed lightGray' : '1px dashed white'
            }} >
            <Box ref={hoverRef} sx={{
                height: 345,
                border: props.isEditing ? (isHovered ? '1px dashed blue' : '1px dashed lightGray') : 'none',
                boxShadow: (props.isEditing && isHovered) ? "0px 5px 7px #E5E9EC" : 'none'
            }}>
                <label htmlFor={props.data.id} >
                    <Input disabled={!(props.isEditing && isHovered)} accept="image/*" id={props.data.id} multiple type="file" onChange={(e) => { handleFiles(e) }} />
                    <Image
                        alt=""
                        src={(props.data.data as BlockImageData).url || placeholder}
                        loading="lazy"
                    />
                </label>
            </Box>
        </Box>
    )
}

export default BlockImage;