import { useRef, useEffect, useState } from 'react'
import { Grid, Stack, Box } from '@mui/material';
import BlocksContainerMenu from './BlocksContainerMenu';
import BlockTitle, { BlockTitleData } from './BlockTitle';
import BlockImage, { BlockImageData } from './BlockImage';
import BlockSlot, { BlockSlotPanelEventType } from './BlockSlot';
import { BlockData, BlockType } from '../../models/BlockData';
import DataManager from '../../managers/DataManager';

export type BlockEditHandler = (id: string, data: any) => void;

function BlocksContainer() {
    const [items, setItems] = useState<Array<BlockData>>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const dataManager = useRef<DataManager | null>(null);
    const [isEditing, setIsEditing] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const addBlock = (index: number) => {
        switch (index) {
            case BlockType.TITLE:
                dataManager.current?.addBlock(new BlockData(BlockType.TITLE, new BlockTitleData('<p><strong class="ql-size-huge">タイトル</strong></p>')));
                break;
            case BlockType.IMAGE:
                dataManager.current?.addBlock(new BlockData(BlockType.IMAGE, new BlockImageData(undefined)));
                break;
            default:
                break;
        }

        setTimeout(() => {
            scrollToBottom()
        }, 250);
    }

    const undo = () => {
        dataManager.current?.undo();
    }

    const updateHandler = (data: Array<BlockData>) => {
        setItems(data);
    }

    const panelEventHandler = (id: string, event: BlockSlotPanelEventType) => {
        switch (event) {
            case BlockSlotPanelEventType.UP:
                dataManager.current?.moveUp(id)
                break;
            case BlockSlotPanelEventType.DOWN:
                dataManager.current?.moveDown(id)
                break;
            case BlockSlotPanelEventType.TOP:
                dataManager.current?.moveTop(id)
                break;
            case BlockSlotPanelEventType.BOTTOM:
                dataManager.current?.moveBottom(id)
                break;
            case BlockSlotPanelEventType.DUPLICATE:
                dataManager.current?.duplicate(id)
                break;
            case BlockSlotPanelEventType.DELETE:
                dataManager.current?.delete(id)
                break;

            default:
                break;
        }
    }

    const editHandler = (id: string, data: any) => {
        dataManager.current?.updateData(id, data)
    }

    useEffect(() => {
        dataManager.current = new DataManager(updateHandler);
    }, []);

    return (
        <Grid container spacing={2} height="100vh">
            <Grid container item xs={1}>
                <BlocksContainerMenu handleSelect={addBlock} handleUndo={undo} handleEdit={setIsEditing} isEditing={isEditing} canUndo={ (dataManager.current?.canUndo() || false) }/>
            </Grid>
            <Grid container item xs={10}>
                <Box
                    sx={{
                        width: "100%",
                        p: 1, 
                        border: '1px dashed lightGray',
                        overflow: "hidden",
                        overflowY: "scroll"
                    }}
                >
                    <Stack spacing={2}>
                        {items.map(item => {
                            switch (item.type) {
                                case BlockType.TITLE:
                                    return (
                                        <BlockSlot key={item.id} dataId={item.id} isEditing={isEditing} handlePanelEvent={panelEventHandler}>
                                            <BlockTitle data={item} isEditing={isEditing} editHandler={editHandler}/>
                                        </BlockSlot>
                                    )
                                case BlockType.IMAGE:
                                    return (
                                        <BlockSlot key={item.id} dataId={item.id} isEditing={isEditing} handlePanelEvent={panelEventHandler}>
                                            <BlockImage data={item} isEditing={isEditing} editHandler={editHandler}/>
                                        </BlockSlot>
                                    )
                                default:
                                    return (
                                        <div />
                                    )
                            }
                        })}
                        <div ref={messagesEndRef} key="list-bottom" />
                    </Stack>
                </Box>
            </Grid>
            <Grid container item xs={1} />
        </Grid>
    )
}

export default BlocksContainer;