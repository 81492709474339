import React from 'react';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import LibraryAdd from '@mui/icons-material/LibraryAdd';
import Undo from '@mui/icons-material/Undo';
import { BlockType } from '../../models/BlockData';

interface MenuEvent {
    handleSelect: (index: number) => void;
    handleUndo: () => void;
    handleEdit: (value: boolean) => void;
    isEditing: boolean;
    canUndo: boolean;
}

function BlocksContainerMenu({ handleSelect, handleUndo, handleEdit, isEditing, canUndo }: MenuEvent) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        handleClose();
        handleSelect(index);
    };

    const renderEditButton = () => {
        return (
            <Button variant={isEditing ? "contained" : "outlined"} sx={{ width: 90 }} color="primary" onClick={() => { handleEdit(!isEditing) }} >
                {isEditing ? "編集中" : "編集"}
            </Button>
        )
    }

    if (!isEditing) {
        return (
            <Stack spacing={2} position='fixed' >
               { renderEditButton() }
            </Stack>
        );
    }

    return (
        <Stack spacing={2} position='fixed' >
           { renderEditButton() }
            <Button variant="outlined" onClick={handleClick} >
                <LibraryAdd />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={(event) => onMenuItemClick(event, BlockType.TITLE)}>タイトル</MenuItem>
                <MenuItem onClick={(event) => onMenuItemClick(event, BlockType.IMAGE)}>画像</MenuItem>
            </Menu>
            <Button disabled={!canUndo} variant="outlined" onClick={handleUndo} >
                <Undo />
            </Button>
        </Stack>
    );
}

export default BlocksContainerMenu;