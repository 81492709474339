import './App.css';
import { Box } from '@mui/material';
import TopPage from './top/TopPage'

function App() {
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
        >
            <TopPage />
        </Box>
    )
}

export default App
