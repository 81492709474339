import React from 'react';
import { Button, Stack } from '@mui/material';
import useHover from '../../hooks/UseHover';
import { ArrowDownward, ArrowUpward, ContentCopy, DeleteOutlined, VerticalAlignBottom, VerticalAlignTop } from '@mui/icons-material';

export enum BlockSlotPanelEventType {
    UP = 0,
    DOWN = 1,
    TOP = 2,
    BOTTOM = 3,
    DUPLICATE = 4,
    DELETE = 5
}
type BlockSlotPanelEvent = (id: string, event:BlockSlotPanelEventType) => void;

interface BlockSlotProps {
    children: React.ReactNode,
    dataId: string,
    isEditing: boolean,
    handlePanelEvent: BlockSlotPanelEvent;
}

function BlockSlot({ children, dataId, isEditing, handlePanelEvent }: BlockSlotProps) {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    return (
        <Stack ref={hoverRef} sx={{ position: 'relative' }}>
            { isHovered && isEditing &&
                <Stack direction='row' spacing={2} sx={{
                    position: 'absolute',
                    backgroundColor: '#E5E9EC80',
                    top: 10,
                    right: 10
                }}>
                    <Button onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.UP) }} >
                        <ArrowUpward />
                    </Button>
                    <Button onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.DOWN) }} >
                        <ArrowDownward />
                    </Button>
                    <Button onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.TOP) }} >
                        <VerticalAlignTop />
                    </Button>
                    <Button onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.BOTTOM) }} >
                        <VerticalAlignBottom />
                    </Button>
                    <Button onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.DUPLICATE) }} >
                        <ContentCopy />
                    </Button>
                    <Button color='warning' onClick={() => { handlePanelEvent(dataId, BlockSlotPanelEventType.DELETE) }} >
                        <DeleteOutlined />
                    </Button>
                </Stack>
            }
            {children}
        </Stack>
    )
}

export default BlockSlot;