import { Grid } from '@mui/material';
import useHover from '../../hooks/UseHover';
import { BlockData } from '../../models/BlockData';
import EditableText from '../EditableText';
import { BlockEditHandler } from './BlocksContainer';

export class BlockTitleData {
    title: string

    constructor(title: string) {
        this.title = title;
    }
}

function BlockTitle(props: { data: BlockData, isEditing: boolean, editHandler: BlockEditHandler }) {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const handleUpdate = (text: string) => {
        props.editHandler(props.data.id, new BlockTitleData(text));
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
                height: 120,
                overflow: "hidden",
                overflowY: "scroll",
                border: props.isEditing ? '1px dashed lightGray' : 'none'
            }}
        >

            <Grid item ref={hoverRef} sx={{
                border: props.isEditing ? (isHovered ? '1px dashed blue' : '1px dashed lightGray') : 'none',
                boxShadow: (props.isEditing && isHovered) ? "0px 5px 7px #E5E9EC" : 'none'
            }}>
                <EditableText
                    text={(props.data.data as BlockTitleData).title}
                    isEditable={props.isEditing}
                    updateHandler={handleUpdate}
                />
            </Grid>

        </Grid>
    )
}

export default BlockTitle;